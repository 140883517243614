
import React from 'react';

const Home = () => {
  return (
    <div >
    <header className="App-header">
    <img src="PlanPals logo.jpg" className="App-logo" alt="logo" />
    Collaborate on Travel Plans!
    </header>
</div>
  );
};

export default Home;